import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"

const PricePage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <link
        href="https://fonts.googleapis.com/earlyaccess/nicomoji.css"
        rel="stylesheet"
      ></link>
      <link
        href="https://fonts.googleapis.com/css?family=M+PLUS+Rounded+1c"
        rel="stylesheet"
      ></link>
      <main>
        <div className="image-heading">
          <StaticImage
            src="../images/price-top.png"
            width={4000}
            alt="price-top"
          />
        </div>
        <div className="price-introduction">
          <h2>制作料金のめやす - お見積り例をご紹介</h2>
          <p>
            ホームページの制作料金は、会社や内容によって異なることがあります。
          </p>
          <p>
            そのため、お客様のニーズに合わせたお見積りを行うことが大切です。
          </p>
          <p>
            そこで、よくあるお見積り例を3パターンご紹介いたします。ご検討のめやすにしてください。
          </p>
          <p>また、「スタンダードの内容そのままで」というご依頼もOKです。</p>
          <p>
            無料で概算お見積りさせていただきますので、お気軽にお問い合わせください。
          </p>
        </div>
        <div className="price-type-wrapper">
          <div className="price-type-cord">
            <div className="price-cord-lowcost-title">
              <h2>ローコスト</h2>
              <p>お見積りパターン</p>
              <div className="price-cord-subtitle">
                <p>まずは必要最低限のページだけ作成したい方</p>
              </div>
            </div>
            <div className="price-cord-body-white">
              <StaticImage
                src="../images/document.png"
                width={31}
                alt="price-cord-body-icon"
                className="price-cord-body-icon"
              />
              <p>トップページ</p>
            </div>
            <div className="price-cord-body-beige">
              <StaticImage
                src="../images/document.png"
                width={31}
                alt="price-cord-body-icon"
                className="price-cord-body-icon"
              />
              <StaticImage
                src="../images/document.png"
                width={31}
                alt="price-cord-body-icon"
                className="price-cord-body-icon"
              />
              <p>下層ページ2P</p>
            </div>
            <div className="price-cord-body-white price-cord-body-function">
              <StaticImage
                src="../images/mobile.png"
                width={31}
                height={34}
                alt="price-cord-body-icon"
                className="price-cord-body-icon"
              />
              <p>モバイル対応</p>
            </div>
            <div className="price-cord-body-beige">
              <p>基本料金（提案・画面設計・構成 / 進行管理費）</p>
            </div>
            <div className="price-cord-body-price">
              <h2>20万円台〜</h2>
            </div>
            <div className="price-cord-body-explanation">
              <p>
                お求めやすい価格でのホームページ制作を実現するため、ローコストモデルをご用意しております。
                こちらのモデルは、お客様からのご要望を基に、必要最小限の機能を搭載したホームページ制作を行います。
                また、後から機能の追加やデザインのカスタマイズも可能ですので、お客様のニーズに合わせて柔軟に対応させていただきます。
              </p>
            </div>
          </div>
          <div className="price-type-cord">
            <div className="price-cord-standard-title">
              <h2>スタンダード</h2>
              <p>お見積りパターン</p>
              <div className="price-cord-subtitle">
                <p>必要十分な機能をしっかりと実装したプラン</p>
              </div>
            </div>
            <div className="price-cord-body-white">
              <StaticImage
                src="../images/document.png"
                width={31}
                alt="price-cord-body-icon"
                className="price-cord-body-icon"
              />
              <p>トップページ</p>
            </div>
            <div className="price-cord-body-beige">
              <StaticImage
                src="../images/document.png"
                width={31}
                alt="price-cord-body-icon"
                className="price-cord-body-icon"
              />
              <StaticImage
                src="../images/document.png"
                width={31}
                alt="price-cord-body-icon"
                className="price-cord-body-icon"
              />
              <StaticImage
                src="../images/document.png"
                width={31}
                alt="price-cord-body-icon"
                className="price-cord-body-icon"
              />
              <p>下層ページ3P</p>
            </div>
            <div className="price-cord-body-white price-cord-body-function">
              <StaticImage
                src="../images/mobile.png"
                width={31}
                height={34}
                alt="price-cord-body-icon"
                className="price-cord-body-icon"
              />
              <StaticImage
                src="../images/email.png"
                width={31}
                alt="price-cord-body-icon"
                className="price-cord-body-icon price-cord-body-icon-email"
              />
              <p>モバイル・タブレット対応</p>
              <p>お問い合わせ機能</p>
            </div>
            <div className="price-cord-body-beige">
              <p>基本料金（提案・画面設計・構成 / 進行管理費）</p>
            </div>
            <div className="price-cord-body-price">
              <h2>35万円台〜</h2>
            </div>
            <div className="price-cord-body-explanation">
              <p>
                基本的な機能を兼ね備えた、スタンダードな料金プランです。お問い合わせページから販促をしたい方におすすめのプランです、このモデルでは、google
                analyticsやgoogle search
                consoleを導入したり、SEO対策を考慮されたWebページにサポートしています。迷ったらこちらがおすすめです。
              </p>{" "}
            </div>
          </div>
          <div className="price-type-cord">
            <div className="price-cord-premium-title">
              <h2>プレミアム</h2>
              <p>お見積りパターン</p>
              <div className="price-cord-subtitle">
                <p>お知らせを自社で簡単に更新したい</p>
              </div>
            </div>
            <div className="price-cord-body-white">
              <StaticImage
                src="../images/document.png"
                width={31}
                alt="price-cord-body-icon"
                className="price-cord-body-icon"
              />
              <p>トップページ</p>
            </div>
            <div className="price-cord-body-beige">
              <StaticImage
                src="../images/document.png"
                width={31}
                alt="price-cord-body-icon"
                className="price-cord-body-icon"
              />
              <StaticImage
                src="../images/document.png"
                width={31}
                alt="price-cord-body-icon"
                className="price-cord-body-icon"
              />
              <StaticImage
                src="../images/document.png"
                width={31}
                alt="price-cord-body-icon"
                className="price-cord-body-icon"
              />
              <StaticImage
                src="../images/document.png"
                width={31}
                alt="price-cord-body-icon"
                className="price-cord-body-icon"
              />
              <p>下層ページ4P</p>
            </div>
            <div className="price-cord-body-white price-cord-body-function">
              <StaticImage
                src="../images/mobile.png"
                width={31}
                height={34}
                alt="price-cord-body-icon"
                className="price-cord-body-icon"
              />
              <StaticImage
                src="../images/email.png"
                width={31}
                alt="price-cord-body-icon"
                className="price-cord-body-icon price-cord-body-icon-email"
              />
              <StaticImage
                src="../images/setting.png"
                width={31}
                alt="price-cord-body-icon"
                className="price-cord-body-icon"
              />
              <p>モバイル・タブレット対応</p>
              <p>お問い合わせ機能</p>
              <p>記事投稿機能</p>
            </div>
            <div className="price-cord-body-beige">
              <p>基本料金（提案・画面設計・構成 / 進行管理費）</p>
            </div>
            <div className="price-cord-body-price">
              <h2>50万円台〜</h2>
            </div>
            <div className="price-cord-body-explanation">
              <p>
                基本的な機能にさらに、自身で記事を更新できる、記事更新システム(ヘッドレスCMS)を導入したプランです。しっかりとSEOで上位を狙いたい方や、お休みなどの情報をブログ形式で掲載したい方にもおすすめです。
              </p>{" "}
            </div>
          </div>
        </div>
      </main>
    </Layout>
  )
}

export const Head = () => (
  <Seo
    title="ホームページ制作 料金表"
    description="ホームページの制作料金の詳細はこちらから。シンプルで明確な料金体系を目指しております。お客様のお悩みを解決するために全力でサポートいたします。"
  />
)

export default PricePage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
